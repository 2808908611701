<template>
  <div>
    <v-text-field
      :value="fileName"
      :label="$t('global.file')"
      color="tertiary"
      readonly
      clearable
      @click="selectFile"
      @click:clear="onClearClick"
    />
    <input type="file" style="display: none" ref="fileInput" :accept="accept" @change="onFileSelected" />
  </div>
</template>
<script>
  export default {
    name: "FileInput",
    props: {
      value: File,
      accept: {
        type: String,
        required: false,
        default:
          "image/png,image/jpg,image/jpeg,.doc,.docx,.xlsx,.txt,application/vnd.ms-excel,application/msword,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.wordprocessingml.document.glossary+xml,application/vnd.openxmlformats-officedocument.wordprocessingml.document.main+xml"
      }
    },
    computed: {
      innerValue: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit("input", val);
        }
      },
      fileName() {
        return this.innerValue ? this.innerValue.name : "";
      }
    },
    methods: {
      selectFile() {
        this.$refs.fileInput.click();
      },
      validateFile(file) {
        return new Promise((resolve, reject) => {
          const isFormalExtension =
            file.type === "image/png" ||
            file.type === "image/jpeg" ||
            file.type === "image/jpg" ||
            file.type === "application/msword" ||
            file.type === "application/pdf" ||
            file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
            file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document.main+xml";
          const isLt2M = file.size / 1024 / 1024 < 2;
          if (!isFormalExtension) {
            return reject(new Error(this.$t("file_format_error")));
          }
          if (!isLt2M) {
            return reject(new Error(this.$t("file_size_error")));
          }
          resolve(true);
        });
      },
      onFileSelected(event) {
        const file = event.target.files[0];
        if (file) {
          this.validateFile(file)
            .then(valid => {
              if (valid) {
                this.innerValue = file;
              }
            })
            .catch(error => {
              this.$refs.fileInput.value = null;
              this.$helpers.showNotification(error.message, "error", true);
            });
        }
      },
      onClearClick() {
        this.innerValue = null;
      }
    }
  };
</script>
